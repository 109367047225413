// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[14].oneOf[1].use[1]!../../../../../node_modules/swiper/css/swiper.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".swiper-button-next,.swiper-button-prev{color:#4f4b42;font-weight:700}@media only screen and (max-width:767px){.swiper-button-next:after,.swiper-button-prev:after{font-size:2rem}}", "",{"version":3,"sources":["webpack://./resources/js/components/Slider/assets/style.scss"],"names":[],"mappings":"AAAoC,wCAAwC,aAAa,CAAC,eAAgB,CAAC,yCAA0C,oDAAoD,cAAc,CAAC","sourcesContent":["@import\"~swiper/css/swiper.min.css\";.swiper-button-next,.swiper-button-prev{color:#4f4b42;font-weight:bold}@media only screen and (max-width: 767px){.swiper-button-next:after,.swiper-button-prev:after{font-size:2rem}}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
