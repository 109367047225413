import Swiper from "swiper";
import '../Slider/assets/style.scss';



window.slider = function slider(container = 'swiper-container', slidesM = 1, slidesT = 2, slidesP = 3, slidesX=3, ) {
    return new Swiper('.' + container, {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            575: {
                slidesPerView: slidesT,
            },
            991: {
                slidesPerView: slidesP,
            },
            1199: {
                slidesPerView: slidesX,
            }
        },
        slidesPerView: slidesM,
        spaceBetween:15
    });
};

window.slider = function sliderBooks(container = 'swiper-container-books', slidesM = 2, slidesT = 3, slidesP = 4, slidesX = 4, ) {
    return new Swiper('.' + container, {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            575: {
                slidesPerView: slidesT,
            },
            991: {
                slidesPerView: slidesP,
            },
            1199: {
                slidesPerView: slidesX,
            }
        },
        slidesPerView: slidesM,
        spaceBetween:15
    });
};

window.sliderOffers = function sliderOffers(container = 'swiper-container', slidesX = 3) {
    return new Swiper('.' + container, {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            575: {
                slidesPerView: 1.5,
            },
            767: {
                slidesPerView: 2.2,
            },
            991: {
                slidesPerView: 2.5,
            },
            1199: {
                slidesPerView: slidesX,
            }
        },
        slidesPerView: 1.2,
    });
};

